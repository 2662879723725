import PlanApi from "@/components/Api/planApi"
import { Notify } from 'vant';
import { dateFormatHM } from '@/utils/unit'
/**
 * Toc 的行程轴数据整合
 * @param {*} params  {planId: "",budgetMainId: ""}
 */
export const changePlanInfo = async function (params) {
  return new Promise((pro) => {
    Promise.all([getPlanDay(params), getBudgetList(params.budgetMainId,params.collectionLinkData)]).then(resAry => {
      let num = resAry[0].budgetMain.useNumber;
      let platDays = resAry[0]
      let budgets = resAry[1]
      let _platDays = []
      let _citys = []
      let _myBudgetList = []
      platDays.budgetMain.planDayList.forEach(item => {
        let _planDay = {}
        _planDay.planDay = item.planDay
        _planDay.planDate = item.planDate
        _planDay.planId = item.planId
        let cityValue = ""
        _citys.push(...item.cityList)
        item.cityList.forEach(city => {
          cityValue = cityValue + city.cityName + "-"
        })
        _planDay.cityStr = cityValue
        if (cityValue.indexOf("-") !== -1) {
          _planDay.cityStr = cityValue.slice(0,cityValue.length-1)
        }
        _planDay.cityList = item.cityList
        let _filterBudgets = budgets.filter(x => x.day == item.planDay)
        _filterBudgets.forEach(bud => {
          // var budgetListArr = []
          // let startHours = new Date(bud.stayDate).getHours() || "00";
          // let startMinutes = new Date(bud.stayDate).getMinutes() || "00";
          // let endHours = new Date(bud.endStayDate).getHours() || "00";
          // let endMinutes = new Date(bud.endStayDate).getMinutes() || "00";
          // let start = startHours + ":" + startMinutes;
          // let end = endHours + ":" + endMinutes;
          let _budgetList = {};
          let start = dateFormatHM(bud.stayDate,':')
          let end = dateFormatHM(bud.endStayDate,':')
          if (start !== "00:00" && end !== "00:00") {
            _budgetList.timeStr = start + "-" + end;
          }else if(start !== "00:00" && end === "00:00"){
            _budgetList.timeStr = start + '出发'
          }else if(start === "00:00" && end !== "00:00"){
            _budgetList.timeStr = end + '结束'
          }else {
            _budgetList.timeStr = null
          }
          let resourceDetail = bud.resourceDetail
            ? bud.resourceDetail
            : {};
          _budgetList.day = bud.day;
          _budgetList.resourceNameCn = bud.resourceNameCn;
          _budgetList.remark = bud.remark
          // _budgetList.imageUrl = resourceDetail.imageUrl;
          _budgetList.imageUrl = bud.imageUrl;
          _budgetList.introduce = bud.introduce
          // _budgetList.introduction = resourceDetail.introduction;
          _budgetList.addressCn = bud.addressCn;
          _budgetList.state = bud.state;
          _budgetList.isCheck = bud.isCheck
          _budgetList.star = bud.star;
          _budgetList.endStayDate = bud.endStayDate;
          _budgetList.stayDate = bud.stayDate;
          resourceDetail.imageUrl = _budgetList.imageUrl
          resourceDetail.nameCn = bud.resourceNameCn
          resourceDetail.nameEn = bud.resourceNameEn
          _budgetList.resourceDetail = resourceDetail
          let stockDetail = [];
          if (bud.details.length > 0) {
            // 乘汇率的价格
            _budgetList.totalPrice = bud.details
              .map((de) => de.ratio * de.stockNumber * de.rate)
              .reduce((n, m) => n + m);
            _budgetList.currency = bud.details[0].currency;

            // 不乘汇率的价格
            _budgetList.totalPriceNotRate = bud.details
                .map((de) => de.ratio * de.stockNumber)
                .reduce((n, m) => n + m);
            _budgetList.currency = bud.details[0].currency;
            bud.details.forEach((budDetails) => {
              let detail = budDetails.stockDetail ? JSON.parse(budDetails.stockDetail) : {};
              let _stockDetail = {};
              if(JSON.stringify(detail) !== '{}') {
              _stockDetail.budgetType = detail.budgetType;
              _stockDetail.timeDesc = detail.priceList[0].timeDesc;
              _stockDetail.productNameCn = detail.productNameCn;
              _stockDetail.priceCompany = detail.priceList[0].priceCompany;
              _stockDetail.useTime = detail.priceList[0].useTime;
              _stockDetail.ratio = budDetails.ratio;
              _stockDetail.rate = budDetails.rate;
              _stockDetail.stockDesc = detail.stockDesc;
              _stockDetail.currency = budDetails.currency;
              _stockDetail.stockNumber = budDetails.stockNumber;
              _stockDetail.productDesc = detail.productDesc;
              stockDetail.push(_stockDetail);
              }
            });
          }
          _budgetList.stockDetail = stockDetail;
          _myBudgetList.push(_budgetList);
          // _planDay.budgetList = budgetListArr
        })
        _planDay.budgetListStr = JSON.stringify(_myBudgetList.filter(u => u.day === _planDay.planDay))
        _planDay.budgetListFs = JSON.parse(_planDay.budgetListStr)
        console.log("_platDays.push===>_budgetList before",_planDay)
        _platDays.push(_planDay)
      })
      console.log("_platDays.push===>",_platDays)
      _platDays.forEach(i => i.budgetList = i.budgetListFs)
      let obj = {
        planMap: resAry[0].budgetMain.planMap,
        _allCitys: _citys,
        _platDays: _platDays,
         num:num,
         planDetail:resAry[0].budgetMain,
         userId: resAry[0].createId,
         mechanismId: resAry[0].createCompanyId,
         allArr:resAry[1]
      };
      pro(obj);
    })
    // .catch((err) => {
    //   console.log("err",err)
    //   Notify({ type: 'danger', message: err });
    // })
  })
}

function getPlanDay(params) {
  return new Promise(res => {
    new PlanApi("plan").planDetail(params).then((result) => {
      if (result.status == 200) {
        res(result.data)
      }
    }).catch((err) => {
      Notify({ type: 'danger', message: '系统异常！' });
    });
  })
}

async function getBudgetList(budgetMainId,collectionLinkData) {
  return new Promise(res => {
    new PlanApi("plan").budgetList({budgetMainId: budgetMainId,collectionLinkData: collectionLinkData}).then((result) => {
      if (result.status == 200) {
         res(result.data)
      }
    }).catch((err) => {
      Notify({ type: 'danger', message: '系统异常！' });
    });
  })
}
