import { Service, ServiceToC } from "../Axios/Service";
import qs from "qs";
const PROFILE = {
  plan: "VUE_APP_PLAN_PREFIX",
};

function getType(apiType) {
  return process.env[PROFILE[apiType]];
}

class PlanApi {
  constructor(ApiType) {
    this.ApiType = ApiType;
  }

  //行程详情
  planDetail(data) {
    let apiType = getType(this.ApiType);
    return ServiceToC({
      url: `/${apiType}/h5Api/planInfo/planDetail`,
      method: "post",
      data: qs.stringify(data),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  }

  //价格说明数据
  queryByCompanyId(data) {
    let apiType = getType(this.ApiType);
    return ServiceToC({
      url: `/${apiType}/h5Api/quoted/queryByCompanyId`,
      method: "post",
      data: qs.stringify(data),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  }
  //推特图列表查询
  tweetList(data) {
    let apiType = getType(this.ApiType);
    return ServiceToC({
      url: `/${apiType}/h5Api/planInfo/tweetList`,
      method: "post",
      data: data,
    });
  }
  //渲染h5方案详情后面的数据
  referResource(data) {
    let apiType = getType(this.ApiType);
    return ServiceToC({
      url: `/${apiType}/h5Api/planInfo/referResource`,
      method: "post",
      data: qs.stringify(data),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  }
  //预算单中间数据查询
  budgetList(data) {
    let apiType = getType(this.ApiType);
    return ServiceToC({
      url: `/${apiType}/h5Api/planInfo/list`,
      method: "post",
      data: qs.stringify(data),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  }

    qyNationalImage(data) {
      let apiType = getType(this.ApiType);
      return ServiceToC({
        url: `/${apiType}/h5Api/planInfo/qyNationalImage`,
        method: "post",
        data: qs.stringify(data),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
    }
}

export default PlanApi;
